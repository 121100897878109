<template>
  <div class="about">
    <TitleHeader msg="委托书下载" ></TitleHeader>
    <el-row>
        <el-col :span="24"  class="tipsInfo">委托书：<el-button type="text" size="small" @click.native="WTSDownload()">查看下载</el-button></el-col>
    </el-row>
  </div>
</template>

<script>
import { DownloadWTS } from "api/product.js";
//  import Qs from 'qs'
const TitleHeader = () => import("components/common/TitleHeader.vue");
export default {
  name: "WTSDOWN",
  data() {
    return {
    };
  },
  methods: {
    WTSDownload(){
         DownloadWTS().then(res=>{
            if(res.data.code==400){
               this.$message({
                    message:res.data.msg,
                    type: 'warning'
                  });
           }else{
             this.$message({
                    message:res.data.msg,
                    type: 'success'
                  });
            let href=res.data.data.files;
             window.open(href, '_blank', 'toolbar=yes, width=900, height=700')
           }
     
        })
    }
  },
  created() {
  },
  components: {
    TitleHeader,
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.el-table {
  margin-top: 20px;
}
.tipsInfo {
  color: #666;
  font-size:16px;
  padding: 20px;
}
</style>
